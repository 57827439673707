@tailwind base;
@tailwind components;
@tailwind utilities;

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.bg-gray-nilo {
  background: #eef1f5;
}

:root {
  --primary-500: #0d4b90;
}

.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.header-shadow {
  box-shadow: inset 0 4px 4px 0 rgb(0 0 0 / 0.06);
}

.nextjs-toast-errors-parent {
  display: none !important;
}
